import { gutterCalculator } from "../../../util/utils";
import {
  viewport,
  colors,
  font,
  lineHeight,
} from "../../../styles/cssinjs/helpers/variables.js";
export default function FeaturesGrid({
  data,
  colCount = 3,
  isIconsInline = false,
  isAnchorTag = false,
}) {
  const List = ({ data, index }) => {
    let gutterClass = gutterCalculator("gutter-space", index - 1);
    return (
      <li
        className={`${index <= colCount && "lg:mb-8"} 
         ${gutterClass} `}
      >
        {!isAnchorTag ? (
          <div
            className={`icon-title-wrap ${
              isIconsInline && "fx lg:mb-2 md:mb-1"
            } `}
          >
            {data.hasOwnProperty("dimensions") ? (
              <svg
                width={data.dimensions[0]}
                height={data.dimensions[1]}
                className={isIconsInline && "md:mr-1 lg:mr-1"}
              >
                <use xlinkHref={`/sprite.svg#${data.icon}`} />
              </svg>
            ) : (
              <figure
                className={isIconsInline && "md:mr-1 lg:mr-1"}
                dangerouslySetInnerHTML={{ __html: data.icon }}
                aria-hidden="true"
              />
            )}
            <h3 className={`${isIconsInline ? `` : `lg:mt-2 lg:mb-3`}`}>
              {data.title}
            </h3>
          </div>
        ) : (
          <a
            href={data.url}
            className={`icon-title-wrap ${isIconsInline && "fx mb-2"} `}
          >
            <figure
              className={isIconsInline && "md:mr-1 lg:mr-1"}
              dangerouslySetInnerHTML={{ __html: data.icon }}
              aria-hidden="true"
            />
            <h3 className={`${data.url && "text-brandcolor"}`}>{data.title}</h3>
          </a>
        )}

        {Array.isArray(data.desc) ? (
          data.desc.map((index, item) => {
            return <p key={index} dangerouslySetInnerHTML={{ __html: item }} />;
          })
        ) : (
          <>
            <p dangerouslySetInnerHTML={{ __html: data.desc }} />
            {data.note && (
              <p
                className="lg:mt-5 md:mt-2 text-grey-800 text-xxs"
                dangerouslySetInnerHTML={{ __html: data.note }}
              />
            )}
          </>
        )}
        <style jsx>
          {`
            @media (min-width: ${viewport.tablet.md}) {
              .gutter-space {
                margin-left: 40px;
                margin-right: 40px;
              }
            }
            @media (min-width: ${viewport.tablet.sm}) and (max-width: ${viewport
                .tablet.md}) {
              .gutter-space {
                margin-left: 20px;
                margin-right: 20px;
              }
            }
          `}
        </style>
      </li>
    );
  };
  return (
    <ul
      className={`container card--default card--feature fx--${
        (colCount <= 3) & !isIconsInline ? `tl` : `sb`
      }  has-${colCount <= 3 ? `three` : `four`}-col`}
      aria-label="Features."
    >
      {data.map((item, index) => {
        return <List key={index} data={item} index={index + 1} />;
      })}
    </ul>
  );
}
